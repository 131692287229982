import React from "react"
import { Link } from "react-router-dom"
import "../header/Header.css"
import "./Footer.css"
import { Row, Col } from "react-bootstrap"

function Footer() {
	return (
		<div className="main-wrapper ">
			<div className="" style={{ paddingBottom: "50px" }}>
				<Row>
					{/* <Col md={3} sm={12}>
						<div className="footer-logo">
							<img className="" src="../assets/logo-trans.png" alt="GITCO logo" />
						</div>
					</Col> */}
					<Col md={1}></Col>

					<Col md={10} sm={12} style={{ alignSelf: "center" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/strategic-travel-management">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										Services
									</p>
								</Link>
							</li>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/our-story">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										About
									</p>
								</Link>
							</li>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/contact">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										Contact
									</p>
								</Link>
							</li>
						</div>
					</Col>
					<Col md={1}></Col>
				</Row>

				<Row className="pd-t-20" style={{ textAlign: "center" }}>
					<Col className="pd-t-10" sm={12} md={8}>
						<p className="footer-link-offc">&copy; 2021 BigBash Projects Pvt. Ltd.</p>
					</Col>
					{/* <Col className="pd-t-10" sm={12} md={3}>
						<p className="footer-link-offc" style={{ textDecoration: "underline" }}>
							Privacy Policy
						</p>
					</Col>
					<Col className="pd-t-10" sm={12} md={3}>
						<p className="footer-link-offc" style={{ textDecoration: "underline" }}>
							Terms & Conditions
						</p>
					</Col> */}
					<Col className="pd-t-10" sm={12} md={4}>
						<div className="social-div">
							<div className="social-icon">
								<a href="https://www.linkedin.com/company/the-great-indian-travel-co/about/?viewAsMember=true" target="_blank" rel="noreferrer">
									<i className="fab fa-linkedin-in"></i>
								</a>
							</div>
							<div className="social-icon">
								<i className="fab fa-instagram"></i>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Footer
