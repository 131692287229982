import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../home/Home.css"
import MediaQuery from "react-responsive"

function SportTravel() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/sports-travel/1.png" alt="Banner of Sports Travel by GITCO" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img
					style={{ width: "100%", objectFit: "fill", height: "25em" }}
					src="../assets/sports-travel/mob-1.png"
					alt="Banner of Sports Travel by GITCO"
				/>
			</MediaQuery>

			<div className="main-wrapper">
				{/* SECTION 1 */}
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">Sport Travel</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									GITCO’s specialist sports travel division leverages the corporate travel, group travel and event management expertise of the global
									GITCO group to provide winning travel solutions to the sports industry.
								</h5>

								<p>
									From worldwide tours to weekend breaks, when you book with Allure Travel you’ll benefit from the same level of exemplary service and
									great value travel deals you’ve come to expect from GITCO.
								</p>
							</div>
						</Col>
					</Row>
				</div>

				{/* SECTION 2 */}
				<div className="pd-tb-50">
					<h2 className="para-title">We know travel, and we know your goals.</h2>
					<div className="pd-t-20">
						<h6 className="para-subtitle fw-600">
							As sports and travel enthusiasts we understand your game and your goals, and we appreciate that your travel needs are unique.
						</h6>

						<p>
							Like you, we are 100% committed to delivering excellence in our field, through the provision of competitive and collaborative travel
							solutions that enable you and your team to perform to your limits every time.
						</p>
					</div>
				</div>

				{/* SECTION 3 */}
				{/* <div className="pd-tb-40">
					<Row>
						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Sport Travel" />
						</Col>

						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Sport Travel" />
						</Col>
					</Row>
				</div> */}

				{/* SECTION 4 */}
				<div className="pd-tb-40">
					<h2 className="para-title">Sport Groups & Team Travel</h2>
					<div>
						<h5 className="para-subtitle pd-t-20 fw-600">Extra leg room? Excess baggage? We understand the complex travel needs of sports groups.</h5>

						<p>
							Whether it’s match day travel, competitions and training camps or attending a corporate hospitality event, our group travel solutions
							provide the necessary logistical support to drive success.
						</p>

						<p>
							We understand the complex needs of travelling sports groups and leverage our exceptional global supplier relationships to negotiate
							discounted group rates with flexible conditions on your behalf. Our experience and expertise will get your group where they need to be,
							on time and prepared for action!
						</p>

						<h5 className="para-subtitle pd-t-10 fw-600">Services & Products:</h5>

						<ul>
							<li>Flights (scheduled and charter) and accommodation, transfers, insurance & visas</li>
							<li>Excess baggage, seat preferences, group lounge facilities and group check-in</li>
							<li>Hospitality: meals, special dietary requirement and entertainment</li>
							<li>Bulk itinerary management for speed and accuracy</li>
							<li>24/7 emergency travel assistance</li>
							<li>Travel insurance</li>
							<li>Group travel coordinator</li>
							<li>Global supplier negotiations with your preferred suppliers (driving volume discounts and flexible conditions)</li>
							<li>Traveller tracking technology and emergency communications</li>
							<li>Real-time reporting</li>
						</ul>
					</div>
				</div>

				{/* SECTION 5 */}
				{/* <div className="pd-tb-40">
					<Row>
						<Col md={12} sm={12}>
							<MediaQuery minDeviceWidth={426}>
								<img style={{ width: "100%", objectFit: "fill" }} src="../assets/contact/contact-1-desk.png" alt="Banner of Sports Travel by GITCO" />
							</MediaQuery>
							<MediaQuery maxDeviceWidth={425}>
								<img
									style={{ width: "100%", objectFit: "fill", height: "25em" }}
									src="../assets/home/img1.jpeg"
									alt="Banner of Sports Travel by GITCO"
								/>
							</MediaQuery>
						</Col>

						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Sport Travel" />
						</Col>
					</Row>
				</div> */}

				{/* SECTION 6 */}
				<div className="pd-tb-40">
					<h2 className="para-title">Corporate & Executive Travel</h2>
					<div>
						<p className="pd-t-10">
							Our proven sports travel solutions are tailored to the sports market and support productivity, increase efficiency and provide a return
							on investment every time.
						</p>

						<h5 className="para-subtitle pd-t-10 fw-600">Services & Products:</h5>

						<ul>
							<li>Travel bookings: flights, hotels, transfers, car hire, insurance & visas</li>
							<li>
								Travel support: Book via your dedicated travel consulting team, ensuring your executives’ specific travel needs and preferences are
								met every time
							</li>
							<li>VIP travel services: private charter flights, limousine transfers, escorted airport transfers</li>
							<li>Strategic travel policy development</li>
							<li>24/7 emergency travel assistance</li>
							<li>Global supplier negotiations</li>
							<li>Traveller tracking technology and emergency communications</li>
							<li>Expense management solutions</li>
							<li>Traveller tracking technology and emergency communications</li>
							<li>Approval processes</li>
						</ul>
					</div>
				</div>

				{/* SECTION 7 */}
				{/* <div className="pd-tb-40">
					<Row>
						<Col md={12} sm={12}>
							<MediaQuery minDeviceWidth={426}>
								<img style={{ width: "100%", objectFit: "fill" }} src="../assets/contact/contact-1-desk.png" alt="Banner of Sports Travel by GITCO" />
							</MediaQuery>
							<MediaQuery maxDeviceWidth={425}>
								<img
									style={{ width: "100%", objectFit: "fill", height: "25em" }}
									src="../assets/home/img1.jpeg"
									alt="Banner of Sports Travel by GITCO"
								/>
							</MediaQuery>
						</Col>

						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Sport Travel" />
						</Col>
					</Row>
				</div> */}

				{/* SECTION 8 */}
				<div className="pd-tb-40">
					<h2 className="para-title">Fan Travel</h2>
					<div>
						<h5 className="para-subtitle pd-t-20 fw-600">
							Our sports travel experts can provide fan travel solutions that prioritise the same quality service, value for money and reliability
							that we provide sporting teams and managers.
						</h5>
						<p>
							Our flexible approach to fan travel means we can tailor travel solutions for specific sporting events, customise our existing solutions,
							or provide fans with the ability to curate their own experience with unique add-ons. Our sport travel packages allow fans to attend some
							of the largest sporting events both domestically and internationally.
						</p>

						<h5 className="para-subtitle pd-t-10 fw-600">Services & Products:</h5>

						<ul>
							<li>Travel bookings: flights and hotels</li>
							<li>24/7 emergency travel assistance</li>
							<li>Group travel coordinator</li>
							<li>Reserved tickets</li>
							<li>Tickets and premium experiences</li>
							<li>Tailored, customised packages</li>
							<li>Events and travel experiences</li>
						</ul>

						{/* <img className="pd-tb-30" style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Sport Travel" /> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default SportTravel
