import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import storyContent from "./story.json"
import "../home/Home.css"
import "./Story.css"

function OurStory() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="main-wrapper">
			{/* SECTION 1 */}
			<div className="pd-tb-40">
				<Row>
					<Col md={4} className="vert-al">
						<h2 className="para-title">The GITCO Story</h2>
					</Col>
					<Col md={8}>
						<div>
							<h5 className="para-subtitle">
								The Great Indian Travel Co. (GITCO) is a global provider of innovative and cost-effective travel solutions spanning corporate, events,
								leisure, loyalty and wholesale travel.
							</h5>
						</div>
					</Col>
				</Row>
			</div>

			{/* SECTION 2 */}
			<div className="pd-tb-40">
				<h2 className="para-title">Team</h2>

				<div className="">
					<Row>
						<Col md={12} sm={12}>
							<div className="team pd-t-20">
								<img src="../assets/about/founder-1.jpeg" alt="GITCO Founder" className="profile-display-pic" />
								<h4 className="pd-t-20">Sanyam Agarwal</h4>
								<p>Founder</p>

								<div className="team-soc">
									<ul>
										<li>
											<a href="https://www.linkedin.com/in/sanyam-agarwal-089a9752" target="_blank" rel="noreferrer">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</Col>
						{/* <Col md={6} sm={12}>
							<div className="team pd-t-20">
								<img src="../assets/about/founder-2.jpeg" alt="GITCO Founder" className="profile-display-pic" />
								<h4 className="pd-t-20">Himat Singh</h4>
								<p>Founder</p>

								<div className="team-soc">
									<ul>
										<li>
											<a href="https://www.linkedin.com/in/himat-singh-bb7a09156" target="_blank" rel="noreferrer">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</Col> */}
					</Row>
				</div>
			</div>

			{/* SECTION 3 */}
			<div className="">
				{storyContent.map((content, i) => (
					<Row key={i} className={i % 2 === 0 ? "pd-tb-50 section-content-im" : "pd-tb-50"}>
						<Col xs={12} md={12} className="pd-tb-20">
							<div className="pd-lr-20">
								<h2 className="section-title">{content.title}</h2>

								<p className="pd-t-20">{content.description}</p>

								<p className="pd-tb-10">{content.description2}</p>

								{/* <ul className="points">
									{Object.keys(content.steps).map((key, index) =>
										index > 1 ? (
											<li key={index} className={`more-steps-${index}`}>
												{content.steps[key]}
											</li>
										) : (
											<li key={index}>{content.steps[key]}</li>
										)
									)}
								</ul> */}
							</div>
							{/* <button className="read" id={`more-btn-${i}`}>
								Read More <i className="fas fa-angle-down"></i>
							</button> */}
						</Col>
						{/* <Col xs={12} md={6} className="pd-tb-20">
							<img style={{ width: "100%" }} src={content.imgUrl} alt={content.name} />
						</Col> */}
					</Row>
				))}
			</div>
		</div>
	)
}

export default OurStory
