import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../home/Home.css"
import ReactPlayer from "react-player"
import MediaQuery from "react-responsive"

function Strategic() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/strategic/1.png" alt="Banner of Strategic Management by GITCO" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img
					style={{ width: "100%", objectFit: "fill", height: "25em" }}
					src="../assets/strategic/mob-1.png"
					alt="Banner of Strategic Management by GITCO"
				/>
			</MediaQuery>

			<div className="main-wrapper">
				{/* SECTION 1 */}
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">What is strategic travel management?</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									At GITCO, we provide award-winning strategic travel management services to large enterprise and global businesses looking to elevate
									their travel program.
								</h5>

								<p>
									We provide customers with a dedicated strategic account manager who acts as an extension of your team, by designing and delivering a
									travel management program that meets your strategic objectives.
								</p>

								<p>
									GITCO’s account managers handle everything from negotiations with vendors to the day-to-day operations of your travel team, to
									traveller safety, travel program reporting and training. They work alongside your team of dedicated travel consultants, ensuring
									your travel arrangements are organised to meet all of your business needs, factoring in efficiency, safety, schedules and budget.
								</p>
							</div>
						</Col>
					</Row>
				</div>

				{/* SECTION 2 */}
				{/* <div className="pd-tb-50">
					<h2 className="para-title">Strategic Travel Management Solutions</h2>
					<div className="pd-t-30">
						<ReactPlayer width="100%" height="30em" controls url="https://www.youtube.com/watch?v=s4u3RCrzJCc" />
					</div>
				</div> */}

				{/* SECTION 3 */}
				<div className="pd-tb-50">
					<h2 className="para-title">Why Do I Need Strategic Account Management?</h2>
					<div className="pd-t-30">
						<p>If you’re in charge of managing business travel for your company, you know there’s a lot more to it than meets the eye.</p>

						<p className="pd-t-10">
							It can be both time consuming and complex managing travel programs that are both compliant with company policy and within budget, while
							meeting the needs of everyone’s busy schedule. And there is no set and forget; being able to manage last minute cancellations, itinerary
							changes and unforeseen emergencies is crucial to avoiding disruptions to the flow of your business.
						</p>

						<p className="pd-t-10">
							With GITCO, your dedicated account management team looks after it all, streamlining and simplifying your travel management experience so
							you can focus on what’s important.
						</p>

						<MediaQuery minDeviceWidth={426}>
							<img style={{ width: "100%", objectFit: "fill" }} src="../assets/strategic/2.png" alt="Banner of Strategic Management by GITCO" />
						</MediaQuery>
						<MediaQuery maxDeviceWidth={425}>
							<img
								style={{ width: "100%", objectFit: "fill", height: "25em" }}
								src="../assets/strategic/mob-2.png"
								alt="Banner of Strategic Management by GITCO"
							/>
						</MediaQuery>
					</div>
				</div>

				{/* SECTION 4 */}
				<div className="pd-tb-40">
					<Row>
						<h2 className="para-title">Why Choose GITCO?</h2>
						<div>
							<h5 className="para-subtitle pd-t-30 fw-600">You’ll enjoy a dedicated team</h5>

							<p>
								Our teams are lead by strategic account managers who understand the complex travel needs of large enterprise and global businesses,
								across industries and regions. Importantly, we also know that no two businesses are the same, and no one program fits all. That’s why
								your GITCO account manager partners with you; by understanding your business we can ensure you’re provided with a bespoke travel
								management program that meets your needs and unique travel objectives.
							</p>

							<h5 className="para-subtitle fw-600">We’re committed to continued improvement</h5>

							<p>
								At GITCO, we’re at the cutting edge of travel technology and service delivery. With innovative systems and solutions, our strategic
								account managers gather valuable insights for identifying opportunities to reduce costs, manage compliance and mitigate risk. Working
								on your behalf, our team continues to monitor, develop and refine your travel management program to make travel simpler for you, and
								deliver a measurable return on your investment.
							</p>

							<h5 className="para-subtitle fw-600">We provide global support</h5>

							<p>
								Unlike many travel management companies, we also provide 24/7 global travel support that’s managed locally and never outsourced. That
								means you and your travelling workforce can enjoy peace of mind, knowing that we’ve got you covered anywhere, anytime.
							</p>

							<h3 className="para-subtitle pd-t-10 fw-600">Our business means that you can focus on yours. That’s the GITCO difference.</h3>
						</div>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default Strategic
